













































































































































































































import { Vue, Component } from "vue-property-decorator";
import { SaleArticle, SalePDF } from "@/types/sales/pdf";

// hier folgen die Bild und PDF Dateien für Sales
@Component
export default class Sale extends Vue {
    private privatePDFs: SalePDF[] = [
        {
            name: "SPECIAL-XMAS. 2024",
            pdf: "./pdfs/SPECIAL-XMAS-2024.pdf",
            img: require("@/assets/pdf/img/Terra-Flyer_SPECIAL-XMAS-2024-SkibaLogo_Vorschau_Bild.png"),
        },
    ];
    private businessPDFs: SalePDF[] = [
        {
            name: "buisness_III. 2024",
            pdf: "./pdfs/buisness_III_2024.pdf",
            img: require("@/assets/pdf/img/buisness_III_2014_Voranzeige.png"),
        },
    ];

    private privateArticles: SaleArticle[] = [];

    private businessArticles: SaleArticle[] = [];
    private businessCustomer = false;
}
